@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --background-primary: #eef1fc;
    --background-secondary: #5F679C;
    --background-white: #fefefe;
    --text-white: #fefefe;
    --text-black: #0E0E0E;
    --primary-color: #D14242;
    --primary-color-hover: #e63131;
    --secondary-color: #5F679C;
    --secondary-color-hover: #272a7a;
}

body {
    color: var(--text-black);
    background: var(--background-white);
}

html { scroll-behavior: smooth; }

.lucide {
    /* Change this! */
    color: var(--primary-color);
    width: 56px;
    height: 56px;
    stroke-width: 2px;
}

@layer utilities {
    .text-balance {
        text-wrap: balance;
    }
}

@layer components {
    .icons-default {
        width: 24px !important;
        height: 24px !important;
    }
}